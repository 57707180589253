import React from "react";
import { Box } from "@mui/material";
import Marquee from "react-fast-marquee";
import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  patnerGrass,
} from "../components/SmallComponents/Images";

// const patnersArray = [
//   { img: coinMarketCap, link: "#" },
//   { img: techBullion, link: "#" },
//   { img: cryptoPotato, link: "#" },
//   { img: cryptoSlate, link: "#" },
// ];

const patnerOneArray = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
];

const patnerTwoArray = [
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
];
function Patners() {
  return (
    <Box pt={6} bgcolor="#e9e1cb">
      <Box my={3}>
        <Marquee
          speed={50}
          pauseOnClick={true}
          pauseOnHover={true}
          gradient={false}
          autoFill={true}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            width="100%"
          >
            {patnerOneArray.map((img, i) => (
              // <a key={img + i} href={link} style={{ textDecoration: "none" }}>
              <Box
                key={img}
                component={"img"}
                alt={""}
                src={img}
                width="200px"
                marginRight={"100px"}
              />
              // </a>
            ))}
          </Box>
        </Marquee>
      </Box>
      <Box my={3}>
        <Marquee
          direction="right"
          speed={50}
          pauseOnClick={true}
          pauseOnHover={true}
          gradient={false}
          autoFill={true}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            width="100%"
          >
            {patnerTwoArray.map((img, i) => (
              // <a key={img + i} href={link} style={{ textDecoration: "none" }}>
              <Box
                key={img}
                component={"img"}
                alt={""}
                src={img}
                width="200px"
                marginRight={"100px"}
              />
              // </a>
            ))}
          </Box>
        </Marquee>
      </Box>
      <Box>
        <Box component={"img"} alt={""} src={patnerGrass} width={"100%"} />
      </Box>
    </Box>
  );
}

export default Patners;
