import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { faqBg, faqImg, hBg } from "../components/SmallComponents/Images";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";

const faqTextArray = [
  {
    head: "Who is Pugcoin ($PUG)?",
    para: "Like his brothers SHIB, DOGE, FLOKI and BONK, the PUG dog wants to become one of the biggest players in the memecoin business! To achieve this, the Pugcoin is striving for an even greater rise. You can find more information in the whitepaper! ",
  },
  {
    head: "Why should I buy $PUG?",
    para: "Very simple! You certainly missed DOGE, SHIB, FLOKI and BONK. Don't miss what could be your last chance! Like its competitors, PUG is one of the dogs that will dominate the memecoin world.",
  },
  {
    head: "Where can I buy $PUG?",
    para: "$PUG is listed and currently trading on UNISWAP. Click on the 'Buy' Button and you will be redirected to UNISWAP website or copy the contract address",
  },
  {
    head: "How to stay up to date?",
    para: "On the website you will find our official telegram group. Here you will always find the latest news and you can exchange with other investors and admins. You can also follow us on social media to make sure you don't miss anything.",
  },
  {
    head: " How can I contact the support?",
    para: " If you have any problems, ask in the telegram group. Or write us an email at: contact@pugcoin.club",
  },
  {
    head: " Is $PUG safe?",
    para: "Pugcoin is not associated with a use case and therefore has the same investment risk as any other memecoin. But 99% of all other memecoins have the problem of being fraudulent by adding questionable coding to the code. We want to provide full transparency and let the German company 'SolidProof' audit the smart contract and achieved a perfect score in the audit.",
  },
];

function FAQ() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const matches = useMediaQuery("(max-width:750px)");
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel, index) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      id="faqs"
      sx={{
        background: `url(${faqBg}) no-repeat center`,
        backgroundSize: "100% 100%",
        py: 2,
        mb: -0.5,
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            mt={6}
            sx={{
              color: "#ffffff",
              backgroundImage: `url(${hBg})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              py: { xs: 3, sm: 4 },
              px: { xs: 5, sm: 9 },
              textAlign: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          >
            <Typography
              pl={2}
              variant="h5"
              sx={{
                fontFamily: "Wagoon Bold",
                fontSize: { xs: "24px", md: "48px" },
                fontWeight: "400",
                lineHeight: "48px",
              }}
            >
              FREQUENTLY ASKED QUESTIONS
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          spacing={6}
          justifyContent="center"
          my={4}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid
            item
            data-aos={mobileMatches ? "fade-up" : "fade-right"}
            data-aos-delay="30"
            data-aos-duration="2000"
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              gap={4}
              sx={{
                width: { xs: "100%", sm: "90%", md: "80%", margin: "auto" },
              }}
            >
              {faqTextArray.map(({ head, para }, i) => (
                <Accordion
                  key={`panel${i + 1}`}
                  expanded={expanded === `panel${i + 1}`}
                  onChange={handleChange(`panel${i + 1}`)}
                  slotProps={{ transition: { timeout: 600 } }}
                  sx={{
                    borderRadius: "30px",
                    background:
                      "linear-gradient(180deg, #FED319 -26.27%, #EE7D08 133.33%)",
                    boxShadow:
                      "0px 11.24506950378418px 22.49013900756836px 0px #00000080",
                    "&.MuiAccordion-root:before": {
                      height: "0px !important",
                    },
                    "&.MuiPaper-root": {
                      borderRadius: "30px !important",
                    },
                    width: "100%",
                    m: "auto",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `panel${i + 1}` ? (
                        <Remove
                          style={{
                            color: "#fff",
                            fontSize: "25px",
                            background:
                              "linear-gradient(180deg, #FED319 -26.27%, #EE7D08 133.33%)",
                            borderRadius: "100px",
                            boxShadow: " 0px 0px 10px #00000080",
                          }}
                        />
                      ) : (
                        <Add
                          style={{
                            color: "#fff",
                            fontSize: "25px",
                            background:
                              "linear-gradient(180deg, #FED319 -26.27%, #EE7D08 133.33%)",
                            borderRadius: "100px",
                            boxShadow: " 0px 0px 10px #00000080",
                          }}
                        />
                      )
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Box
                      fontSize={matches ? "17px" : "21px"}
                      fontWeight={matches ? 500 : 600}
                      px={2}
                      py={1}
                      color="#ffffff"
                      fontFamily="Wagoon Bold"
                    >
                      {head}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      pl={2}
                      pb={3}
                      fontSize={{ xs: "14px", sm: "16px" }}
                      textAlign="left"
                      fontWeight="400"
                      color="#ffffff"
                      fontFamily="Montserrat"
                    >
                      {para}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            data-aos={mobileMatches ? "fade-down" : "fade-left"}
            data-aos-delay="30"
            data-aos-duration="2000"
          >
            <Box>
              <Box
                component="img"
                alt=""
                src={faqImg}
                sx={{ width: { xs: "100%", sm: "100%", md: "70%" } }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default FAQ;
