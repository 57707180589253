import { Box, Container } from "@mui/system";
import React from "react";
import {
  ChartD,
  TokenomicsImg,
  hBg,
  TokenomicsMainbg,
  ChartM,
  TokenomicsImgM,
} from "../components/SmallComponents/Images";
import { Grid, Typography, useMediaQuery } from "@mui/material";

const Tokenomics = () => {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const isMobileOrTablet = useMediaQuery("(max-width: 960px)");
  const TokenomicsData = [
    { title: "Marketing", value: "13.6%", PUG: "285,600,000 PUG" },
    { title: "CEX Liquidity", value: "8%", PUG: "168,000,000 PUG" },
    { title: "DEX Liquidity", value: "5%", PUG: "105,000,000 PUG" },
    { title: "Team", value: "2%", PUG: "42,000,000 PUG" },
  ];
  return (
    <Box
      id="tokenomics"
      mt={10}
      pb={5}
      sx={{
        backgroundImage: isMobileOrTablet
          ? `url(${TokenomicsImg})`
          : `url(${TokenomicsImgM})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: {
          xs: "155% 99%",
          sm: "102% 100%",
          md: "130% 100%",
          xl: "100% 100%",
          lg: "115% 100%",
        },
        backgroundPosition: "center",
        height: "fit-content",
        width: { xs: "100%", sm: "100%" },
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            mt={5}
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mt: {
                    xs: 27,
                    sm: 20,
                    md: 18,
                  },
                  color: "#ffffff",
                  backgroundImage: `url(${hBg})`,
                  backgroundSize: { xs: "100% 75%", sm: "100% 100%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",

                  textAlign: "center",
                  height: "100px",
                  width: { xs: "450", sm: "260px", md: "465px" },
                }}
              >
                <Typography
                  px={5}
                  py={{ xs: 3.5, sm: 4, md: 3.5 }}
                  variant="h5"
                  sx={{
                    fontFamily: "Wagoon Bold",
                    fontSize: { xs: "24px", md: "48px" },
                    fontWeight: "400",
                    lineHeight: "48px",
                    textAlign: "center",
                  }}
                >
                  Tokenomics
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                mt: 3,
                width: { xs: "300px", sm: "580px", md: "780px" },
                height: { xs: "180px", sm: "300px", md: "380px" },
              }}
            >
              <Box
                component="img"
                src={isMobileOrTablet ? ChartM : ChartD}
                alt="Chart"
                sx={{
                  height: "100%",
                  width: "100%",
                  "&:hover": {
                    transform: "scale(1.1)",
                    transition: "transform 0.5s",
                  },
                }}
              />
            </Box>
          </Grid>
          {TokenomicsData.map(({ title, value, PUG }, index) => (
            <Grid
              data-aos={
                index > 1
                  ? mobileMatches
                    ? "fade-down"
                    : "fade-left"
                  : mobileMatches
                  ? "fade-down"
                  : "fade-right"
              }
              data-aos-delay="30"
              data-aos-duration="2000"
              item
              key={index}
              mt={5}
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Wagoon Bold",
                  fontSize: { xs: "30px", md: "48px" },
                  fontWeight: "400",
                  lineHeight: "28px",
                  color: "#F9CD13",
                }}
              >
                {value}
              </Typography>
              <Box
                sx={{
                  mt: 1,
                  backgroundImage: `url(${TokenomicsMainbg})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "107px",
                  width: "238px",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  pt={4}
                  variant="h6"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    color: "#FFFFFF",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  pt={1}
                  variant="h6"
                  sx={{
                    fontFamily: "Wagoon Bold",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    color: "#FFFFFF",
                  }}
                >
                  {PUG}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Tokenomics;
