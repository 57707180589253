import React from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "../components/Header";
import {
  heroSectionBg1,
  preSaleBoxGrass,
  solidWhite,
} from "../components/SmallComponents/Images";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import PresaleBox from "../components/SmallComponents/PresaleBox";

function HeroSection() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const matchesTwo = useMediaQuery("(max-width:1050px)");
  const matchesThree = useMediaQuery("(max-width:1250px)");

  return (
    <>
      <Box
        id="home"
        sx={{
          background: "#F7AC11",
          pb: 3,
          backgroundImage: `url(${preSaleBoxGrass})`,
          backgroundSize: "300px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom right 50px",
        }}
      >
        <Header />
        <Container maxWidth="xl">
          <Grid
            container
            my={{ xs: 2, md: 4 }}
            rowSpacing={{ xs: 2, md: 4 }}
            columnSpacing={{ xs: 1, md: 4 }}
            justifyContent={"center"}
            // alignItems={{ xs: "center", md: matches ? "center" : "inherit" }}
            flexDirection={{
              xs: "column-reverse",
              md: matchesTwo ? "column-reverse" : "row",
            }}
          >
            <Grid
              item
              xs={12}
              sm={10}
              md={matchesTwo ? 8 : 5}
              sx={{
                margin: { xs: "auto", md: matchesTwo ? "auto" : "inherit" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                data-aos={mobileMatches ? "fade-up" : "fade-right"}
                data-aos-delay="30"
                data-aos-duration="2000"
                // mt={{ xs: 0, md: 4 }}
                sx={{
                  height: { xs: "70%", md: matchesTwo ? "70%" : "80%" },
                  backgroundImage: `url(${heroSectionBg1})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  px: { xs: 8, sm: 10, md: matchesTwo ? 13 : 10 },
                  pt: { xs: 12, md: matchesTwo ? 10 : matchesThree ? 8 : 0 },
                  pb: { xs: 8, md: matchesTwo ? 8 : matchesThree ? 8 : 0 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  {/* <Stack
                    sx={{
                      py: 1,
                      mb: 2,
                      background: "#ffd100",
                      border: "1px solid #EE7D08",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "12px", sm: "14px" },
                      color: "#000000",
                      fontWeight: "600",
                      width: "fit-content",
                      boxShadow: "-2px 13px 21px -6px rgba(0,0,0,0.75)",
                      px: 1,
                      lineHeight: "18px",
                      borderRadius: "8px",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Official Listing Date on Uniswap: 4th of July
                  </Stack> */}
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Wagoon Bold",
                      fontSize: {
                        xs: "26px",
                        sm: "38px",
                        md: "48px",
                        lg: "40px",
                        xl: "48px",
                      },
                      fontWeight: "400",
                      lineHeight: { xs: "32px", sm: "48px" },
                      color: "#D89F00",
                      textShadow: "2px 2px 4px #F6C089",
                    }}
                  >
                    You missed DOGE? <br /> You missed SHIB? <br />
                    PUG is here!
                  </Typography>
                  <Typography
                    variant="h5"
                    my={2}
                    sx={{
                      color: "#818181",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "10px", sm: "12px" },
                      fontWeight: "400",
                      lineHeight: {
                        xs: "13px",
                        md: matchesTwo ? "13px" : "18px",
                      },
                    }}
                  >
                    The top 2 Memecoins of all time have one thing in common:
                    They are funny dogs who took over the investors heart with
                    their attractive design and meme. Well Pugcoin is no
                    different and wants to repeat history years later. The
                    little pug wants to dominate the exchanges, social media and
                    magazines soon!
                  </Typography>
                  <Box
                    mt={{ xs: 2, md: 3 }}
                    mb={{ xs: 2, md: 2 }}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={{ xs: "column", sm: "row" }}
                    flex
                    gap={2}
                  >
                    <StyledButton
                      href="./PUG_Whitepaper.pdf"
                      target="_blank"
                      btnStatus={true}
                      width={{ xs: "180px", sm: "150px" }}
                    >
                      Whitepaper
                    </StyledButton>
                    <StyledButton
                      href="./SmartContract_Audit_Solidproof_PugCoin.pdf"
                      target="_blank"
                      btnStatus={true}
                      width={{ xs: "180px", sm: "150px" }}
                    >
                      Audit
                    </StyledButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* {matches ? null : ( */}
            <Grid
              item
              xs={12}
              sm={12}
              md={matchesTwo ? 10 : 6.5}
              sx={{
                margin: { xs: "inherit", md: matchesTwo ? "auto" : "inherit" },
              }}
            >
              <PresaleBox />
            </Grid>
            {/* )} */}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
