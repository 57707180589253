import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { hBg, mediaBg } from "../components/SmallComponents/Images";
import video from "../Images/mediaVideo.MP4";

function Media() {
  return (
    <Box
      sx={{
        background: `url(${mediaBg}) no-repeat center`,
        backgroundSize: "100% 100%",
        py: 3,
        my: 2,
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            mt={6}
            sx={{
              color: "#ffffff",
              backgroundImage: `url(${hBg})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              py: { xs: 2, sm: 4 },
              px: { xs: 5, sm: 9 },
              textAlign: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          >
            <Typography
              pl={2}
              variant="h5"
              sx={{
                fontFamily: "Wagoon Bold",
                fontSize: { xs: "24px", md: "48px" },
                fontWeight: "400",
                lineHeight: "48px",
              }}
            >
              Media
            </Typography>
          </Box>
        </Box>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            my: 3,
          }}
        >
          <Box
            data-aos="fade-up"
            data-aos-delay="30"
            data-aos-duration="2000"
            width="80%"
            sx={{
              pt: 0.8,
              px: 0.8,
              borderRadius: "10px",
              backgroundColor: "#EE7D08",
            }}
          >
            <video
              controls
              muted
              // autoPlay
              crossOrigin="anonymous"
              loop
              playsInline
              preload="auto"
              role="presentation"
              disablePictureInPicture={true}
              tabIndex="-1"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
              }}
            >
              <source src={video} type="video/mp4" />
            </video>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export default Media;
