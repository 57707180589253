import { useContext, useState, useEffect } from "react";

import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import Footer from "./components/Footer";
import {
  HeroSection,
  HowToBuy,
  WannaKnow,
  InfluencerSection,
  // PreSale,
  Tokenomics,
  Media,
  Patners,
  AddressSection,
  FAQ,
  RoadMap,
  // LiveSale,
  AllStar,
} from "./pages";
import { Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  AOS.init();
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <Patners />
              <AddressSection />
              <HowToBuy />
              <WannaKnow />
              <InfluencerSection />
              {/* <PreSale /> */}
              <Tokenomics />
              <RoadMap />
              {/* <LiveSale /> */}
              <Media />
              <AllStar />
              <FAQ />
              <Footer />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
